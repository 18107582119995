import React from "react";

const WhoWeAre = () => {
  return (
    <div id="about" className="max-w-7xl mx-auto p-4  mt-4">
      <div className="lg:flex gap-4 py-16">
        <div className="lg:w-1/2 p-6 space-y-6 ">
          <div className="bg-[#DEBE75] w-20 h-1"></div>
          <div className="space-y-4">
            <h4 className="text-[42px] text-[#191919] font-semibold">
              Who We Are
            </h4>
            <p className="lg:w-[95%] text-justify">
              {`Sleek Footwear stands at the intersection of style and substance, with a commitment to delivering footwear that not only looks stunning but also feels incredible. With a network of 10 shops spread across Tamilnadu, our brand has become synonymous with modern elegance and unbeatable comfort.`}
              <br />
              <br />
              {`At Sleek Footwear, our passion extends beyond just selling shoes; it's about providing an unforgettable shopping experience. Our knowledgeable staff are dedicated to helping you find the perfect pair, offering personalized advice and ensuring the ideal fit. Step into any of our 10 locations and immerse yourself in a world where style meets comfort.`}
            </p>
          </div>
        </div>
        <div className="w-1/2 hidden lg:block">
          <img
            src="/assets/about-list.jpg"
            alt="about-us"
            className="hover:shadow-2xl transition duration-500 hover:shadow-[#DEBE75] rounded-3xl"
          />
        </div>
      </div>
    </div>
  );
};

export default WhoWeAre;
