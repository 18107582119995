import React from "react";

const contents = [
  { link: "#about", head: "About Us" },
  { link: "#product", head: "Product" },
  { link: "#brand", head: "Brand" },
];

const Header = () => {
  return (
    <header className="sticky top-0 z-50">
      <div className="flex justify-between items-center py-[22px] px-7 sticky  bg-white  md:shadow-lg">
        <div>
          <a href="/">
            <img
              src="/assets/sleek-logo.png"
              alt="logo"
              width={120}
              height={50}
            />
          </a>
        </div>
        <div className="hidden md:space-x-12 md:flex">
          {contents.map((content, index) => {
            return (
              <a
                className="hover:text-[#DEBE75] text-[#414141] font-medium cursor-pointer"
                key={index}
                href={content.link}
              >
                {content.head}
              </a>
            );
          })}
        </div>
        <div>
          {" "}
          <a
            href={"#contact-us"}
            className="px-4 py-2 md:px-7 md:py-3 rounded-md bg-[#DEBE75] text-white hover:bg-[#414141]"
          >
            Contact Us
          </a>
        </div>
      </div>
      {/* <div className="flex space-x-6 px-12 py-3 items-center bg-white justify-between shadow-lg md:hidden">
        {contents.map((content) => {
          return <p className="hover:text-[#1EA69A] font-medium cursor-pointer" key={content.id}>{content.head}</p>
        })} 
        </div> */}
    </header>
  );
};

export default Header;
