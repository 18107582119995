import React from "react";
import { FaLocationArrow } from "react-icons/fa6";
import { MdPhoneInTalk } from "react-icons/md";

const Support = [
  { name: "Pricing" },
  { name: "Documentation" },
  { name: "Guides" },
  { name: "API Status" },
];

const Company = [
  { name: "About" },
  { name: "Blog" },
  { name: "Jobs" },
  { name: "Press" },
  { name: "Partners" },
];

const Footer = () => {
  return (
    <>
      <footer
        id="contact-us"
        className="bg-white mt-14"
        aria-labelledby="footer-heading"
      >
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="bg-[#DEBE75]">
          <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 ">
            <div className="xl:grid xl:grid-cols-3 xl:gap-8 ">
              <div className="space-y-8 xl:col-span-1">
                <img
                  src="/assets/sleek-logo.png"
                  alt="logo"
                  width={80}
                  height={45}
                />
                <p className="text-gray-800 text-base">
                  {`Sleek Footwear, with 10 locations, is your destination for stylish yet comfortable shoes. Our curated collection ensures there's something for every taste, while our knowledgeable staff are dedicated to providing personalized service to help you find the perfect fit.`}
                </p>
                <div className="flex space-x-6">
                  <a href="#" className="text-gray-500 hover:text-gray-200">
                    <span className="sr-only">Facebook</span>
                    <svg
                      className="h-6 w-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>

                  <a href="#" className="text-gray-500 hover:text-gray-200">
                    <span className="sr-only">Instagram</span>
                    <svg
                      className="h-6 w-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
                <div className="md:grid md:grid-cols-2 md:gap-8">
                  <div>
                    <h3 className="text-sm font-bold text-[#3B2A56] tracking-wider uppercase">
                      Company
                    </h3>
                    <ul role="list" className="mt-4 space-y-4">
                      {Company.map((item, index) => {
                        return (
                          <li key={index}>
                            <a
                              href="#"
                              className="text-base hover:text-gray-100 text-gray-800"
                            >
                              {item.name}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="mt-12 md:mt-0">
                    <h3 className="text-sm font-bold text-[#3B2A56] tracking-wider uppercase">
                      Support
                    </h3>
                    <ul role="list" className="mt-4 space-y-4">
                      {Support.map((item, index) => {
                        return (
                          <li key={index}>
                            <a
                              href="#"
                              className="text-base hover:text-gray-100 text-gray-800"
                            >
                              {item.name}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div>
                  <h3 className="text-sm font-bold text-[#3B2A56] tracking-wider uppercase">
                    Location
                  </h3>
                  {/* <div className="pt-4 space-y-4">
                    <h3 className="text-[13px] font-bold text-[#3B2A56]  tracking-wider uppercase">
                      Agraharam
                    </h3>
                    <div className="py-1 flex items-center gap-2">
                      <FaLocationArrow />
                      <p className="text-sm">
                        46, First Agraharam, Opp. Auntyuncle, Salem-636 001.
                      </p>
                    </div>
                    <div className="py-1 flex items-center gap-2">
                      <MdPhoneInTalk />
                      <p className="text-xs md:text-sm">
                        0427-2266845 , +91 994 477 1357
                      </p>
                    </div>
                  </div> */}
                  <div className="pt-4 space-y-4">
                    <h3 className="text-[13px] font-bold  text-[#3B2A56]  tracking-wider uppercase">
                      Four Roads
                    </h3>
                    <div className="py-1 flex items-center gap-2">
                      <FaLocationArrow />
                      <p className="text-sm">
                        101, Pookoodai Complex, Four Roads, Salem - 636 009.
                      </p>
                    </div>
                    <div className="py-1 flex items-center gap-2">
                      <MdPhoneInTalk />
                      <p className="text-xs md:text-sm">
                        0427 2350204 , +91 984 398 8551
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="  border-gray-200 p-6 bg-[#191919]">
          <p className="text-base text-gray-300 xl:text-center">
            &copy; <span className="text-white">2020 Sleek </span>, Inc. All
            rights reserved | Website Designed by{" "}
            <span className="text-[#DEBE75]">
              <a href="https://www.nekhop.com" target="_blank">
                Nekhop Technology Services.
              </a>
            </span>
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
