import React, { useRef } from "react";
import Slider from "react-slick";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { TfiAngleRight, TfiAngleLeft } from "react-icons/tfi";

const images = [
  {
    id: 1,
    src: "/assets/my-sleek-brands-01.jpg",
    alt: "Brand 1",
  },
  {
    id: 2,
    src: "/assets/my-sleek-brands-02.jpg",
    alt: "Brand 2",
  },
  {
    id: 3,
    src: "/assets/my-sleek-brands-03.jpg",
    alt: "Brand 3",
  },
  {
    id: 4,
    src: "/assets/my-sleek-brands-04.jpg",
    alt: "Brand 4",
  },
  {
    id: 5,
    src: "/assets/my-sleek-brands-05.jpg",
    alt: "Brand 5",
  },
  {
    id: 6,
    src: "/assets/my-sleek-brands-06.jpg",
    alt: "Brand 6",
  },
  {
    id: 7,
    src: "/assets/my-sleek-brands-07.jpg",
    alt: "Brand 7",
  },
  {
    id: 8,
    src: "/assets/my-sleek-brands-08.jpg",
    alt: "Brand 8",
  },
  {
    id: 9,
    src: "/assets/my-sleek-brands-09.jpg",
    alt: "Brand 9",
  },
  {
    id: 10,
    src: "/assets/my-sleek-brands-10.jpg",
    alt: "Brand 10",
  },
  {
    id: 11,
    src: "/assets/my-sleek-brands-11.jpg",
    alt: "Brand 11",
  },
  {
    id: 12,
    src: "/assets/my-sleek-brands-12.jpg",
    alt: "Brand 12",
  },
  {
    id: 13,
    src: "/assets/my-sleek-brands-13.jpg",
    alt: "Brand 13",
  },
  {
    id: 14,
    src: "/assets/my-sleek-brands-14.jpg",
    alt: "Brand 14",
  },
  {
    id: 15,
    src: "/assets/my-sleek-brands-15.jpg",
    alt: "Brand 15",
  },
  {
    id: 16,
    src: "/assets/my-sleek-brands-16.jpg",
    alt: "Brand 16",
  },
  {
    id: 17,
    src: "/assets/my-sleek-brands-17.jpg",
    alt: "Brand 17",
  },
  {
    id: 18,
    src: "/assets/my-sleek-brands-18.jpg",
    alt: "Brand 18",
  },
  {
    id: 19,
    src: "/assets/my-sleek-brands-19.jpg",
    alt: "Brand 19",
  },
];

const BrandLogoSlider = () => {
  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 5,
    speed: 500,
    // adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  return (
    <>
      <h1
        id="brand"
        className="text-[30px] lg:text-[40px] font-bold flex justify-center items-center tracking-wide w-full py-7"
      >
        Brands
      </h1>
      <div className="relative group flex justify-center items-center  md:h-full lg:h-full px-5">
        <Slider
          ref={(slider) => {
            sliderRef = slider;
          }}
          {...settings}
          className=" overflow-x-hidden md:max-w-3xl lg:max-w-7xl "
          style={{ outline: "none" }}
        >
          {images.map((data) => (
            <div
              className="w-full lg:ml-7 focus: outline-none md:h-full lg:h-full"
              key={data.id}
            >
              <img
                src={data.src}
                alt={data.alt}
                className="w-[100px] md:w-[150px] lg:w-[200px] aspect-auto"
              />
            </div>
          ))}
        </Slider>
        <div className="absolute text-xl text-white bg-[#DEBE75] rounded-sm  p-1 arrow arrow-left left-10 lg:left-20 top-1/2 outline-none opacity-0 group-hover:opacity-100 transition-opacity duration-300 hidden md:block">
          <FaChevronLeft onClick={previous} />
        </div>
        <div className="absolute text-xl text-white bg-[#DEBE75] rounded-sm  p-1  arrow arrow-right right-10 lg:right-20 top-1/2 outline-none opacity-0 group-hover:opacity-100 transition-opacity duration-300 hidden md:block">
          <FaChevronRight onClick={next} />
        </div>
      </div>
    </>
  );
};

export default BrandLogoSlider;
