import React from "react";

const cards = [
  {
    id: 1,
    bg: "#FCEDDA",
    src: "/assets/shipping.png",
    alt: "Shipping",
    title: "Free Shipping & Return",
    subTitle: "On all order over ₹999",
  },
  {
    id: 2,
    bg: "#F2FBCB",
    src: "/assets/service.png",
    alt: "Support",
    title: "Customer Support 24/7",
    subTitle: "Instant access to support",
  },
  {
    id: 3,
    bg: "#F7D8F9",
    src: "/assets/payment.png",
    alt: "Payment",
    title: "100% Secure Payment",
    subTitle: "We ensure secure payment!",
  },
];

const ServiceCrads = () => {
  return (
    <div className="max-w-full mx-auto">
      <div className="flex flex-col md:flex-row justify-center  md:space-x-6  mb-12 mx-3 md:mx-8 lg:mx-[120px] space-y-6 md:space-y-0">
        {cards.map((data) => (
          <div
            className={`flex flex-col lg:flex-row justify-center  items-center max-w-[500px] md:w-[360px] lg:w-auto py-10  md:px-3 md:py-10 lg:px-16 lg:py-10 space-y-4 lg:space-y-0 lg:space-x-6 rounded ${
              data?.id == 1 ? "" : ""
            } `}
            key={data?.id}
            style={{ backgroundColor: data?.bg }}
          >
            <div className="">
              <img src={data?.src} alt={data?.alt} className="w-full" />
            </div>
            <div className="flex flex-col justify-center items-center lg:items-start">
              <p className="text-base md:text-lg font-medium text-[#00234D] tracking-wider">
                {data?.title}
              </p>
              <p className=" mt-1 text-sm md:text-base tracking-wide">
                {data?.subTitle}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServiceCrads;
