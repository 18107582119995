import Header from "./components/Header";
import SimpleSlider from "./components/Slider";
import ServiceCrads from "./components/ServiceCrads";
import PopularProducts from "./components/PopularProducts";
import Footer from "./components/Footer";
import SporstTemplate from "./components/SporstTemplate";
import BrandLogoSlider from "./components/BrandLogoSlider";
import WhoWeAre from "./components/who-we-are";

function App() {
  return (
    <div>
      <Header />
      <SimpleSlider />
      <WhoWeAre />
      <ServiceCrads />
      <PopularProducts />
      <SporstTemplate />
      <BrandLogoSlider />
      <Footer />
    </div>
  );
}

export default App;
