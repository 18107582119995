import React from "react";

const productgrida = [
  { id: 1, img: "/assets/p1.jpg", alt: "Product 1", title: "Sports" },
  { id: 2, img: "/assets/p2.jpg", alt: "Product 2", title: "Kids Fancy" },
  { id: 3, img: "/assets/p3.jpg", alt: "Product 3", title: "Sneakers" },
  { id: 4, img: "/assets/p4.jpg", alt: "Product 4", title: "Formals" },
];

const productgridb = [
  { id: 5, img: "/assets/p5.jpg", alt: "Product 5", title: "Boots" },
  { id: 6, img: "/assets/p6.jpg", alt: "Product 6", title: "High Heels" },
  { id: 7, img: "/assets/p7.jpg", alt: "Product 7", title: "Sandals" },
  { id: 8, img: "/assets/p8.jpg", alt: "Product 8", title: "Kids Sports" },
];

const PopularProducts = () => {
  return (
    <div id="product" className="w-full">
      <h1 className="text-[30px] md:text-[43px] font-[650] flex justify-center items-center tracking-wide w-full">
        Popular Products
      </h1>
      <div className="py-10 flex lg:block px-2 md:px-14">
        <div className="flex flex-col lg:flex-row  justify-center items-center space-y-24 lg:space-y-0 lg:space-x-7 w-full">
          {productgrida?.map((data) => {
            return (
              <div
                key={data?.id}
                className="w-[170px] h-[210px]  md:w-[290px] md:h-[370px] group"
              >
                <img
                  src={data?.img}
                  alt={data?.alt}
                  width={290}
                  height={370}
                  className="mx-auto  hover:shadow-xl shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px] "
                />
                <p className="text-lg mt-5 font-semibold flex justify-center items-center text-[#151616] group-hover:text-[#DEBE75]">
                  {data?.title}
                </p>
              </div>
            );
          })}
        </div>
        <div className="flex flex-col lg:flex-row  justify-center items-center space-y-24 lg:space-y-0 lg:space-x-7 lg:mt-24 w-full">
          {productgridb?.map((data) => {
            return (
              <div
                key={data?.id}
                className="w-[170px] h-[210px]  md:w-[290px] md:h-[370px] group"
              >
                <img
                  src={data?.img}
                  alt={data?.alt}
                  width={290}
                  height={370}
                  className="mx-auto shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px] hover:shadow-xl"
                />
                <p className="text-lg mt-5 font-semibold flex justify-center items-center text-[#151616] group-hover:text-[#DEBE75]">
                  {data?.title}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PopularProducts;
