import React from "react";

const ShoeTemplate = [
  {
    id: 1,
    img: "/assets/shoeTemplate-1.jpg",
    alt: "Template 1",
    title: "Sports Shoes",
    subTitle: "25% off for sports men",
  },
  {
    id: 2,
    img: "/assets/shoeTemplate-2.jpg",
    alt: "Template 2",
    title: "Sports Shoes",
    subTitle: "25% off for sports women",
  },
];

const SporstTemplate = () => {
  return (
    <div className="flex flex-col md:flex-row justify-center items-center py-20 space-y-5 md:space-y-0 md:space-x-6 px-3">
      {ShoeTemplate.map((data) => {
        return (
          <div className="relative hover:scale-105 transition-transform duration-500 ease-in-out group" key={data.id}>
            <img
              src={data.img}
              alt={data.alt}
              className="w-[514px] md:w-[330px] lg:w-[614px] rounded"
            />
            <div className="absolute top-3 left-3 ml-8 md:top-0 md:left-2 lg:top-16 lg:left-4 animate-fade-in-up text-[#FFFFFF] max-w-[250px] group-hover:scale-105 transition-transform duration-500">
              <p className=" mt-3 text-xl font-semibold">{data.title}</p>
              <h2 className="text-3xl md:text-4xl mt-3 font-bold">
                {data.subTitle}
              </h2>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SporstTemplate;
