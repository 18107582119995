import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { TfiAngleRight, TfiAngleLeft } from "react-icons/tfi";

const images = [
  {
    id: 1,
    src: "/assets/s1.jpg",
    srcmd: "/assets/s1-m.jpg",
    srcsm: "/assets/s1-s.jpg",
    alt: "Slider1",
    title: "ZEN VIVID 16",
    subTitle: "Look for your inspiration here",
  },
  {
    id: 2,
    src: "/assets/s2.jpg",
    srcmd: "/assets/s2-m.jpg",
    srcsm: "/assets/s2-s.jpg",
    alt: "Slider2",
    title: "PLEATED HEEL",
    subTitle: "Look for your inspiration here",
  },
  {
    id: 3,
    src: "/assets/s3.jpg",
    srcmd: "/assets/s3-m.jpg",
    srcsm: "/assets/s3-s.jpg",
    alt: "Slider3",
    title: "MEN'S SHOES",
    subTitle: "Look for your inspiration here",
  },
];


export default function CustomSlider() {
  const [screenSize, setScreenSize] = useState("sm");

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width >= 1024) {
        setScreenSize("lg");
      } else if (width >= 640) {
        setScreenSize("md");
      } else {
        setScreenSize("sm");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 2000,
    // adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 4000,
    appendDots: (dots) => (
      <div
        style={{
          position: "absolute",
          bottom: "15px",
          width: "100%",
          textAlign: "center",
        }}
      >
        <ul style={{ margin: "0", padding: "0" }}> {dots} </ul>
      </div>
    ),
  };

  return (
      <div className="relative group h-[400px] md:h-full lg:h-full">
      <Slider
        ref={(slider) => {
          sliderRef = slider;
        }}
        {...settings}
        className="overflow-x-hidden "
        style={{ outline: "none" }}
      >
        {images.map((data) => (
          <div className="relative w-full focus: outline-none h-[400px] md:h-full lg:h-full" key={data.id}>
            <img  src={
                screenSize === "lg" ? data.src :
                screenSize === "md" ? data.srcmd :
                data.srcsm
              } alt={data.alt} className="w-full aspect-auto" />
            <div className="absolute top-16 ml-8 md:top-60 md:left-16 lg:top-60 lg:left-32 animate-fade-in-up">
              <h2 className="text-6xl md:text-7xl font-bold">{data.title}</h2>
              <p className="pb-9 mt-3 text-lg tracking-widest">
                {data.subTitle}
              </p>
              <a
                href=""
                className="px-6 py-3 mt-11 rounded-md bg-[#DEBE75] text-white hover:bg-[#414141]"
              >
                SHOP NOW
              </a>
            </div>
          </div>
        ))}
      </Slider>
      <TfiAngleLeft
        onClick={previous}
        className="absolute text-5xl text-gray-500 h-14 arrow arrow-left left-2 top-1/2 outline-none opacity-0 group-hover:opacity-100 transition-opacity duration-300 hidden md:block"
      />
      <TfiAngleRight
        onClick={next}
        className="absolute text-5xl text-gray-500 h-14 arrow arrow-right right-2 top-1/2 outline-none opacity-0 group-hover:opacity-100 transition-opacity duration-300 hidden md:block"
      />
      </div>
  );
}
